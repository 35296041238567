<template>
  <div>
    <sidebar-ownership
      :idsite="idsite"
      :show-open.sync="showOpen"
    />
    <change-project
      :idsite="idsite"
      :show-open-change-project.sync="showOpenChangeProject"
    />
    <archive-site
      :idsite="idsite"
      :show-open-archive-site.sync="showOpenArchiveSite"
    />
    <edit-name-project
      :idsite="idsite"
      :show-open-name-project.sync="showOpenNameProject"
    />

    <b-row v-if="permission === 'owner'">
      <b-col md="12">
        <div
          md="12"
          class="d-flex my-1 col-content-edit-project"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="flat-secondary"
            class="d-flex p-0 mt-75"
            style="height: 32px; width: 32px;"
            @click="viewPalette ? viewPalette = false : viewPalette = true"
            :disabled="permission === 'owner' || permission === 'admin' ? false : true"
          >
            <div
              :style="`width: 32px; height: 32px; background-color: ${projectColor}; border-radius: 5px;`"
            />
          </b-button>
          <v-swatches
            v-if="viewPalette"
            v-model="projectColor"
            popover-x="left"
            swatch-size="20"
            :spacing-size="1"
            inline
            class="ml-1 mt-1"
          />

          <b-row style="max-height: 90px; position: relative; right: -15px;">
            <b-col md="12" class="pl-0">
              <h3 class="mt-1 ml-1">
                {{ projectName.substring(0, 100) + (projectName.length > 100 ? '...' : '') }}
                <feather-icon
                  v-if="permission === 'owner' || permission === 'admin'"
                  icon="Edit2Icon"
                  style="color: #A7AAB9; fill: #A7AAB9;"
                  class="p-0 ml-0 mb-50 cursor-pointer"
                  @click="logIdNameProject"
                />
              </h3>
            </b-col>
          </b-row>
        </div>

        <div class="mt-2 col-content-edit-project">
          <h2
            class=""
            style="font-weight: 700;"
          >
            <span class="mr-1">Manage sites</span>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="info"
              class="py-50 px-1"
              :to="{ name: 'new-site', params: {idPro: $route.params.id, objPr: objPr} }"
              style="border-radius: 10px; margin-bottom: 10px;"
            >
              <feather-icon
                icon="PlusIcon"
              />
              Add site
            </b-button>
          </h2>
        </div>
        <b-input-group
          class="input-group-merge mr-2 mt-1 col-content-edit-project"
          style="width: 100%; height: 38px; border: 1px solid #A7AAB9; border-radius: 22px;"
        >
          <b-input-group-prepend style="border: transparent; border-radius: 20px;">
            <div
              class="input-group-text"
              style="border: transparent; border-radius: 20px;"
            >
              <feather-icon icon="SearchIcon" />
            </div>
          </b-input-group-prepend>
          <b-form-input
            v-model="searchSite"
            placeholder="Search"
            style="border: transparent; background: none; height: 100%;"
          />
        </b-input-group>

        <div class="contain-table-edit-project">
          <div class="top-scroll-horizontal-edit-proj" style="overflow-x: scroll;"><div class="div1" id="tab-edit-p-sc2" style=" height: 20px;"></div></div>
          <b-table
            :items="rows"
            :fields="isDevSite ? columnsDev : columns"
            :filter="searchSite"
            :filter-ignored-fields="['imgSite','ownedBy','action']"
            id="tab-edit-p-sc1"
            class="pb-2 mb-3 tableEditProject table-edit-project-sites"
            striped
            responsive
            show-empty
            sort-icon-right
            empty-filtered-text="Page not found"
          >
            <template #empty="">
              <span></span>
            </template>

            <template #cell(imgSite)="data">
              <b-img-lazy
                :src="data.item.imgSite"
                onerror="this.onerror=null;this.src='https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/site-thumb.png?alt=media&token=6ec3aa77-3ce7-4d16-a70d-10340b8a392b';"
                width="120px"
                height="70px"
                class="my-50"
                loading="lazy"
                :alt="'img not found'"
              />
            </template>

            <template #cell(name)="data"
            >
              <span
                class=""
                style="font-size: 15px; color: #181E38;"
              >
              {{ data.item.name }}
              </span>
            </template>

            <template #cell(domain)="data">
              {{ data.item.domain }}
            </template>

            <template #cell(ownedBy)="data">
              <div v-if="userData.uid !== data.item.ownedBy.uid || !data.item.ownedBy || !data.item.ownedBy.uid">
                <div class="d-flex mb-50" v-if="data.item.own === 'me'">
                  <b-avatar
                    size="27"
                    :src="data.item.ownedBy.foto ? data.item.ownedBy.foto : ''"
                    variant="light-dark"
                    class="badge-minimal"
                    alt="img user"
                    :text="avatarText(!data.item.ownedBy.foto ? data.item.ownedBy.name : '')"
                    style="background: #ddd !important;"
                    v-if="userData.uid === data.item.ownedBy.uid"
                  >
                    <span v-if="!data.item.ownedBy.foto && !data.item.ownedBy.name">{{ avatarText(data.item.ownedBy.name) }}</span>
                  </b-avatar>

                  <div class="ml-25 mt-50">
                    <p class="mb-0" style="font-size: 13px; line-height: 14px; color: #656B85; cursor: initial !important;" v-if="userData.uid === data.item.ownedBy.uid">
                      {{ data.item.ownedBy.name }}
                    </p>

                    <b-dropdown
                      :text="data.item.membership"
                      variant="outline-secondary"
                      class="style-chooser cursor-pointer"
                      :style="rows.length >= 1 && (data.item.permission === 'owner' || data.item.permission === 'admin' || data.item.permission === 'edit' || data.item.permission === 'view') ? 'cursor: initial !important;' : 'cursor: not-allowed !important;'"
                      :disabled="rows.length >= 1 && (data.item.permission === 'owner' || data.item.permission === 'admin' || data.item.permission === 'edit' || data.item.permission === 'view') ? false : true"
                      v-else
                    >
                      <template #button-content>
                        <b-avatar
                          size="27"
                          :src="data.item.ownedBy.foto ? data.item.ownedBy.foto : ''"
                          variant="light-dark"
                          class="badge-minimal"
                          alt="img user"
                          :text="avatarText(data.item.ownedBy.name ? (data.item.ownedBy.name.includes(' ') ? data.item.ownedBy.name : data.item.ownedBy.name + ' ' + data.item.ownedBy.name.charAt(1)) : data.item.ownedBy.email ? data.item.ownedBy.email + ' ' + data.item.ownedBy.email.charAt(1): 'AN')"
                          style="background: #ddd !important;"
                        >
                          <!-- <feather-icon
                            v-if="!data.item.ownedBy.foto && !data.item.ownedBy.name"
                            icon="UserIcon"
                            size="22"
                          /> -->
                          <span v-if="!data.item.ownedBy.foto && !data.item.ownedBy.name && !data.item.ownedBy.email">{{ avatarText(data.item.ownedBy.name) }}</span>
                        </b-avatar>
                        <span class="ml-25 mt-50 mb-0" style="font-size: 13px; line-height: 14px; color: #656B85; text-overflow: ellipsis; overflow: hidden; width: 8rem; display: inline-block;">
                          {{ data.item.ownedBy.name ? data.item.ownedBy.name : data.item.ownedBy.email }}
                        </span>
                      </template>
                      <b-dropdown-item
                        :style="rows.length >= 1 && (data.item.permission === 'view') ? 'cursor: not-allowed; width: 180px; text-align: center;' : 'width: 180px; text-align: center;'"
                        :class="rows.length >= 1 && (data.item.permission === 'view') ? 'cursor-initial' : 'cursor-pointer'"
                        @click="logIdSite(data.item)"
                        :disabled="rows.length >= 1 && (data.item.permission === 'owner' || data.item.permission === 'admin' || data.item.permission === 'edit') ? false : true"
                      >
                        <span style="font-size: 15px; color: #656B85; text-align: center;">
                          Claim
                        </span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>
                <b-button
                  v-else
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  :style="rows.length >= 1 && (data.item.permission === 'view') ? 'pointer-events: initial !important; cursor: not-allowed !important; background: #DCDCF252 !important; border: 1px solid #DCDCF2 !important; border-radius: 10px; color: #656B85 !important; font-size: 16px;' : 'background: #DCDCF252 !important; border: 1px solid #DCDCF2 !important; border-radius: 10px; color: #656B85 !important; font-size: 16px;'"
                  size="sm"
                  class=""
                  @click="logIdSite(data.item)"
                  :disabled="rows.length >= 1 && (data.item.permission === 'owner' || data.item.permission === 'admin' || data.item.permission === 'edit') ? false : true"
                >
                Claim
                </b-button>
                <span style="color: #FC7D7D; font-size: 13px;" class="d-block" v-if="data.item.ownedBy.lost" @click.prevent="showOpenYouLostAccessSidebar = true, idsite = data.item">You lost access <feather-icon icon="HelpCircleIcon" style="color: #FC7D7D;" /></span>
              </div>
              <div v-else>
                <div class="d-flex">
                  <b-avatar
                    size="27"
                    :src="data.item.ownedBy.foto ? data.item.ownedBy.foto : ''"
                    variant="light-dark"
                    class="badge-minimal"
                    alt="img user"
                    style="background: #ddd !important;"
                    :text="avatarText(data.item.ownedBy.name ? (data.item.ownedBy.name.includes(' ') ? data.item.ownedBy.name : data.item.ownedBy.name + ' ' + data.item.ownedBy.name.charAt(1)) : data.item.ownedBy.email ? data.item.ownedBy.email + ' ' + data.item.ownedBy.email.charAt(1): 'AN')"
                  >
                    <span v-if="!data.item.ownedBy.foto && !data.item.ownedBy.name && !data.item.ownedBy.email">{{ avatarText(data.item.ownedBy.name) }}</span>
                  </b-avatar>

                  <div :class="userData.uid === data.item.ownedBy.uid ? 'ml-25 mt-25' : 'ml-25 mt-50'">
                    <p
                      v-if="userData.uid === data.item.ownedBy.uid"
                      class="mb-0 "
                      style="font-size: 13px; line-height: 14px; color: #656B85; text-overflow: ellipsis; overflow: hidden; width: 8rem; display: inline-block;"
                    >
                      Me
                    </p>
                    <p
                      v-else
                      class="mb-0 "
                      style="font-size: 13px; line-height: 14px; color: #656B85; text-overflow: ellipsis; overflow: hidden; width: 8rem; display: inline-block;"
                    >
                      {{ data.item.ownedBy.name ? data.item.ownedBy.name : data.item.ownedBy.email }}
                    </p>
                  </div>
                </div>
                <span
                  v-if="data.item.ownedBy.lost"
                  style="color: #FC7D7D; font-size: 13px;"
                  class=""
                  @click.prevent="showOpenYouLostAccessSidebar = true, idsite = data.item"
                >You lost access <feather-icon
                  icon="HelpCircleIcon"
                  style="color: #FC7D7D;"
                /></span>
              </div>
            </template>

            <template #cell(action)="data">
              <b-dropdown
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                dropleft
                no-caret
                variant="flat-dark"
                class="btn-icon rounded-circle action-edit-project"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="20"
                  />
                </template>
                <b-dropdown-item
                  link-class="d-flex align-items-center  item-dropdown-action-sites"
                  @click="getError(data.item)"
                  :disabled="data.item.permission === 'owner' || data.item.permission === 'admin' || data.item.permission === 'edit' || data.item.permission === 'view' ? false : true"
                >
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                  />
                  Fix
                </b-dropdown-item>
                <b-dropdown-item
                  link-class="d-flex align-items-center  item-dropdown-action-sites"
                  @click="logIdChangeProject(data.item)"
                  :disabled="data.item.permission === 'owner' || data.item.permission === 'admin' ? false : true"
                >
                  <feather-icon
                    icon="RepeatIcon"
                    class="mr-50"
                  />
                  Change Project
                </b-dropdown-item>
                <b-dropdown-item
                  link-class="d-flex align-items-center  item-dropdown-action-sites"
                  @click="logIdArchive(data.item)"
                  :disabled="data.item.permission === 'owner' || data.item.permission === 'admin' ? false : true"
                >
                  <feather-icon
                    icon="ArchiveIcon"
                    class="mr-50"
                  />
                  Archive Site
                </b-dropdown-item>
                <b-dropdown-item
                  link-class="d-flex align-items-center  item-dropdown-action-sites"
                  :to="{ name: 'edit-site', params: { id: data.item.id, project: $route.params.id } }"
                  :disabled="data.item.permission === 'owner' || data.item.permission === 'admin' || data.item.permission === 'edit' ? false : true"
                >
                  <feather-icon
                    icon="SettingsIcon"
                    class="mr-50"
                  />
                  Edit Site
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea, BListGroup, BListGroupItem, BOverlay, BAvatarGroup,
  BFormCheckbox, BFormCheckboxGroup, BFormTimepicker, BAvatar, VBToggle, VBTooltip, BCard, BCardImgLazy, BCardText, BCol, BRow,
  BFormSelect, BDropdownItem, BDropdown, BImgLazy, BBadge, BPagination, BSpinner, BTable,
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { avatarText } from '@core/utils/filter'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
// import { required, minLength, password, confirmed, email, } from 'vuelidate/lib/validators'
import { required, url, email } from '@validations'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import 'vue-good-table/dist/vue-good-table.css'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueAutosuggest } from 'vue-autosuggest'
import { mapActions } from 'vuex'
import VSwatches from 'vue-swatches'
import ClaimOwnership from '@/views/apps/sites/sites-add/ClaimOwnership.vue'
import EditNameProject from './EditNameProject.vue'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import MembersProject from '@/views/extensions/acl/table/sidebar/MembersProject.vue'
import ArchiveSite from '@/views/extensions/acl/table/sidebar/ArchiveSite.vue'
import ChangeProject from '@/views/extensions/acl/table/sidebar/ChangeProject.vue'
import SidebarOwnership from '@/views/extensions/acl/table/sidebar/SidebarOwnership.vue'
import 'vue-swatches/dist/vue-swatches.css'
import { auth, db, firebase } from '@/firebase'

export default {
  components: {
    // sidebar
    SidebarOwnership,
    ChangeProject,
    ArchiveSite,
    MembersProject,
    EditNameProject,

    BCardCode,
    BFormSelect,
    BDropdownItem,
    BDropdown,
    BImgLazy,
    BBadge,
    BPagination,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormGroup,
    BFormTimepicker,
    BAvatar,
    BCard,
    BCardImgLazy,
    BCardText,
    BCol,
    BRow,
    BListGroup,
    BListGroupItem,
    BOverlay,
    BAvatarGroup,
    BSpinner,
    BTable,
    ClaimOwnership,

    // date
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    VueAutosuggest,
    VSwatches,
    VueGoodTable,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      email,
      required,
      //   password,
      //   confirmed,
      firstLoad: true,

      selectedMove: '',
      viewPalette: false,

      // object data project
      projectColor: '#a463bf',
      projectName: '',

      agentesAsignados: '',
      userData: '',
      password: '',
      passwordConfirm: '',
      emailOld: '',
      showDateHour: false,
      selectedPermissions: [],
      allProject: [
        {
          label: 'My sites',
          color: '#F0F1F7',
        },
        {
          label: 'Facade interactive sites',
          color: '#F39C19',
        },
        {
          label: "Jane's sites",
          color: '#1CA085',
        },
      ],
      projectInvitation: '',
      objInvitation: '',
      projectInvitations: [],
      permissionsTeam: [{ title: 'Viewer' }, { title: 'Editor' }, { title: 'Admin/editor' }, { title: 'Remove' }],
      permissionsTeamEdit: [{ title: 'Viewer' }, { title: 'Editor' }, { title: 'Remove' }],
      permissionsTeamInv: [{ title: 'Viewer' }, { title: 'Editor' }, { title: 'Admin/editor' }],
      members: [],
      owner: '',
      loader: true,
      projectInvitationsDOM: [],
      // permission: { title: 'Viewer' },

      // suggest select
      datasuggest: [],
      datasuggestSort: [],
      sortedComplete: false,
      totalUserMember: 0,
      filteredOptions: [],
      inputProps: {
        id: 'autosuggest__input',
        class: 'form-control',
        placeholder: 'Add member...',
      },
      limit: 10,
      selected: null,
      sectionConfigs: {
        destinations: {
          limit: 6,
          label: 'Destination',
          onSelected: selected => {
            this.selected = selected.item
          },
        },
        hotels: {
          limit: 6,
          label: 'Hotels',
          onSelected: selected => {
            this.selected = selected.item
          },
        },
      },

      // table
      searchSite: '',
      columns: [
        {
          label: '',
          key: 'imgSite',
          sortable: false,
          thClass: 'thTableSitesEditProjectImg',
          tdClass: 'tdTableSitesEditProjectImg',
        },
        {
          label: 'Site Name',
          key: 'name',
          thClass: 'thTableSitesEditProjectName',
          tdClass: 'tdTableSitesEditProjectName',
        },
        {
          label: 'URL',
          key: 'domain',
          thClass: 'thTableSitesEditProjectDomain',
          tdClass: 'tdTableSitesEditProjectDomain',
        },
        {
          label: 'Owned by',
          key: 'ownedBy',
          sortable: false,
          thClass: 'thTableSitesEditProjectOwned',
          tdClass: 'tdTableSitesEditProjectOwned',
        },
        {
          label: '',
          key: 'action',
          sortable: false,
          thClass: 'thTableSitesEditProject',
          tdClass: 'tdTableSitesEditProject',
        },
      ],
      columnsDev: [
        {
          label: '',
          key: 'imgSite',
          sortable: false,
          thClass: 'thTableSitesEditProjectImg',
          tdClass: 'tdTableSitesEditProjectImg',
        },
        {
          label: 'Site Name',
          key: 'name',
          thClass: 'thTableSitesEditProjectName',
          tdClass: 'tdTableSitesEditProjectName',
        },
        {
          label: 'URL',
          key: 'domain',
          thClass: 'thTableSitesEditProjectDomain',
          tdClass: 'tdTableSitesEditProjectDomain',
        },
        // {
        //   label: 'Owned by',
        //   key: 'ownedBy',
        //   sortable: false,
        //   thClass: 'thTableSitesEditProject',
        //   tdClass: 'tdTableSitesEditProject',
        // },
        {
          label: '',
          key: 'action',
          sortable: false,
          thClass: 'thTableSitesEditProject',
          tdClass: 'tdTableSitesEditProject',
        },
      ],
      rows: [],
      permission: 'owner',
      permissionInvitation: { title: 'Viewer' },

      // sidebar ownership
      objPr: '',
      idsite: '',
      showOpen: false,
      showOpenChangeProject: false,
      showOpenArchiveSite: false,
      showOpenMembersProject: false,
      showOpenNameProject: false,
      invitationSending: false,
      invitationSended: false,
      validUser: false,
      changePermission: '',
      memberUpdated: '',
      statusPermission: '',
      firstChargePermissions: true,
      removedText: 'removed',
      counter: 0,
      isDevSite: false,
      showOpenComingSoon: false,
      timerInfiniteErrors: null,
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    permission(val) {
      if(val !== 'owner' && val !== 'admin' && val !== 'edit') {
        this.$router.push({name: 'misc-error'})
      }
    },
    searchSite(val) {
      setTimeout(() => {
        const collection = document.getElementsByClassName('vgt-text-disabled')
        if (collection[0] && val) {
          collection[0].innerHTML = 'No results found'
        }
      }, 1000)
    },
    projectInvitation(val) {
      const validateEmail = email => {
        const re = /\S+@\S+\.\S+/
        return re.test(email)
      }
      this.validUser = validateEmail(val)
      // console.log(validateEmail(val));
    },
    changePermission(val) {
      // console.log(val)
      // if(this.firstChargePermissions) {
      //   setTimeout(() => {
      //     this.firstChargePermissions = false
      //   }, 1500)
      // } else {
        // console.log(val.email,val.permission,this.idsite.idsite,this.statusPermission)
        var status = ''
        var statusInv = ''
        var perm = ''
        if(val.permission.title === 'Editor') {
          status = 'active'
          statusInv = 'accepted'
          perm = 'edit'
        } else if(val.permission.title === 'Remove') {
          status = 'inactive'
          statusInv = 'accepted'
          perm = 'remove'
        } else if(val.permission.title === 'Viewer') {
          status = 'active'
          statusInv = 'accepted'
          perm = 'view'
        } else if(val.permission.title === 'Admin/editor') {
          status = 'active'
          statusInv = 'accepted'
          perm = 'admin'
        }
        this.loader = true
        // console.log(this.memberUpdated.id, val.id, this.memberUpdated.perm, perm)
        // if(this.memberUpdated.id !== val.id) {
          db.collection('projects').doc(this.idsite.idsite ? this.idsite.idsite : this.$route.params.id).collection('members').doc(val.id).get()
          .then(docMember => {
            this.memberUpdated = docMember.data()
            this.memberUpdated.id = docMember.id
            this.memberUpdated.perm = perm

            if(docMember.data().status === 'invitated') {
              status = 'invitated'
              statusInv = 'sended'
            }
            db.collection('projects').doc(this.idsite.idsite ? this.idsite.idsite : this.$route.params.id).collection('members').doc(val.id).update({
              status: status,
              statusInv: statusInv,
              perm: perm
            })
            .then(() => {
              if(perm === 'remove') {
                const user = auth.currentUser
                if(user) {
                  db.collection('Users').doc(user.uid).update({
                    updated: Date.now(),
                  })
                }
              }
              // console.log('first')
              // this.charge()
              this.loader = false
              // this.$toast({
              //   component: ToastificationContent,
              //   props: {
              //     title: 'Permissions updated',
              //     icon: 'BellIcon',
              //     variant: 'success',
              //   },
              // })
            })
          })
        // } else {
        //   this.loader = false
        // }
      // }
    },
    datasuggestSort(val) {
      if(val.length === this.totalUserMember && !this.sortedComplete) {
        this.sortedComplete = true
        this.sortMembers()
      }
    },
    projectColor(val) {
      // console.log(val)
      if(this.firstLoad) {
        this.firstLoad = false
      } else {
        db.collection('Projects').doc(this.$route.params.id).update({
          color: val
        }).then(() => console.log('updated color'))

        if(this.userData.uid) {
          db.collection('Users').doc(this.userData.uid).collection('projects').doc(this.$route.params.id).update({
            color: val
          }).then(() => console.log('updated color user project'))
        }
        this.viewPalette = false
      }
    }
  },
  created() {
    this.invitationSending = false
    this.invitationSended = false
    // this.chargeTeamMembers()
    // this.chargeProject(this.$route.params.id)

    this.showOpenComingSoon = false
    this.isDevSite = false

    var urlWindow = window.location.href
    var urlWindowTransform = new URL(urlWindow)
    var urlWindowDomain = urlWindowTransform.hostname
    // console.log(urlWindowDomain)
    if(urlWindowDomain === 'app.seocloud.ai' || urlWindowDomain === 'localhost') {
      // this.isDevSite = true
    }

    const user = auth.currentUser
    if(user) {
      this.userData = {
        uid: user.uid
      }
      this.permission = 'owner'

      db.collection('Users').doc(user.uid)
      .onSnapshot((userDoc) => {
        var teamsVar = userDoc.data().teams && userDoc.data().teams.length ? userDoc.data().teams : []
        if(teamsVar.includes(this.$route.params.id)) {
          db.collection('Projects').doc(this.$route.params.id).get()
          .then(projectDoc => {
            window.document.title = 'Edit project - ' + (projectDoc.data().name) + ' - SEOcloud'
            this.projectName = projectDoc.data().name
            this.projectColor = projectDoc.data().color

            this.objPr = {
              title: projectDoc.data().name,
              icon: projectDoc.data().color,
            }

            db.collection('Projects').doc(this.$route.params.id).collection('sites').where('status', '!=', 'archive').orderBy('status').orderBy('n', 'asc').get()
            .then(sites => {
              if(sites.size >= 1) {
                const arraySitesChildren = []
                this.rows = []
                sites.forEach(site => {
                  if(site.data().status === 'active') {
                    this.rows.push({
                      imgSite: site.data().thumbnail ? site.data().thumbnail : 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/site-thumb.png?alt=media&token=6ec3aa77-3ce7-4d16-a70d-10340b8a392b',
                      name: site.data().name ? site.data().name : site.data().homepage,
                      domainFix: site.data().homepage,
                      domain: site.data().homepage ? site.data().homepage : site.data().WebsiteURL,
                      currentErrors: 0,
                      fixedIssues: '79',
                      key: site.id,
                      alerts: '3',
                      membership: `${site.data().plan ? site.data().plan : 'Free'} license`,
                      ownedBy: site.data().owner && site.data().owner.uid ? site.data().owner  : '',
                      owner: site.data().owner && site.data().owner.uid === user.uid ? 'me' : 'me',
                      member: true,
                      own: site.data().owner && site.data().owner.uid === user.uid ? 'me' : 'other',
                      label: projectDoc.data().name,
                      color: projectDoc.data().color,
                      id: site.id,
                      permission: 'owner',
                      projectid: projectDoc.id,
                    })
                  }
                })

                // this.rows = []
                // this.rows.push({
                //   mode: 'span',
                //   html: false,
                //   label: projectDoc.data().name,
                //   color: projectDoc.data().color,
                //   id: projectDoc.id,
                //   count: 6,
                //   children: arraySitesChildren,
                //   permission: 'owner',
                // })
              }
            })
          })
        } else {
          this.$router.push({ name: 'projects' })
        }
      })
    }

    // horizontal scroll
    this.$nextTick(() => {
      $(".top-scroll-horizontal-edit-proj").scroll(() => {
        // console.log(1)
        $(".table-edit-project-sites").scrollLeft($(".top-scroll-horizontal-edit-proj").scrollLeft());
      });
    });

    setTimeout(() => {
      const miElemento = document.querySelector('.contain-table-edit-project .table-edit-project-sites');
      if(miElemento) {
        miElemento.addEventListener('scroll', () => {
          // console.log(2);
          document.querySelector('.top-scroll-horizontal-edit-proj').scrollLeft = document.querySelector('.contain-table-edit-project .table-edit-project-sites').scrollLeft;
        });
      }
    }, 1200)

    const primerElemento = document.getElementById('tab-edit-p-sc1');
    const segundoElemento = document.getElementById('tab-edit-p-sc2');

    if(primerElemento) {
      const ancho = primerElemento.offsetWidth; // Obtiene el ancho del primer elemento
      segundoElemento.style.width = ancho + 'px';
    }
  },
  mounted() {
    this.timerInfiniteErrors = setInterval(() => {
      this.constructInfiniteEvent()
    }, 1000)
  },
  destroyed() {
    clearInterval(this.timerInfiniteErrors)
  },
  methods: {
    constructInfiniteEvent() {
      // horizontal scroll
      this.$nextTick(() => {
        $(".top-scroll-horizontal-edit-proj").scroll(() => {
          // console.log(1)
          $(".table-edit-project-sites").scrollLeft($(".top-scroll-horizontal-edit-proj").scrollLeft());
        });
      });

      setTimeout(() => {
        const miElemento = document.querySelector('.contain-table-edit-project .table-edit-project-sites');
        if(miElemento) {
          miElemento.addEventListener('scroll', () => {
            // console.log(2);
            document.querySelector('.top-scroll-horizontal-edit-proj').scrollLeft = document.querySelector('.contain-table-edit-project .table-edit-project-sites').scrollLeft;
          });
        }
      }, 1200)

      const primerElemento = document.getElementById('tab-edit-p-sc1');
      const segundoElemento = document.getElementById('tab-edit-p-sc2');

      if(primerElemento) {
        const ancho = primerElemento.offsetWidth; // Obtiene el ancho del primer elemento
        segundoElemento.style.width = ancho + 'px';
      }
    },
    sortMembers() {
      this.datasuggestSort.sort(function (a, b) {
        if (a.name > b.name) {
            return 1;
        }
        if (a.name < b.name) {
            return -1;
        }
        return 0;
      });
      this.datasuggestSort.forEach(item => {
        // console.log(item.name)
        this.datasuggest.push(item.id)
      })
      // console.log(this.arrayTeam.length)
    },
    changeMemberPermission(val) {
      // console.log('first',a)
      var status = ''
      var statusInv = ''
      var perm = ''
      if(val.permission.title === 'Editor' || val.permission === 'Editor') {
        status = val.status === 'invitated' ? val.status : 'active'
        statusInv = val.statusInv === 'sended' ? val.statusInv : 'accepted'
        perm = 'edit'
      } else if(val.permission.title === 'Remove' || val.permission === 'Remove') {
        status = 'inactive'
        statusInv = val.statusInv === 'sended' ? val.statusInv : 'accepted'
        perm = 'remove'
      } else if(val.permission.title === 'Viewer' || val.permission === 'Viewer') {
        status = val.status === 'invitated' ? val.status : 'active'
        statusInv = val.statusInv === 'sended' ? val.statusInv : 'accepted'
        perm = 'view'
      } else if(val.permission.title === 'Admin/editor'  || val.permission === 'Admin/editor') {
        status = val.status === 'invitated' ? val.status : 'active'
        statusInv = val.statusInv === 'sended' ? val.statusInv : 'accepted'
        perm = 'admin'
      }
      this.loader = true

      db.collection('projects').doc(this.$route.params.id ? this.$route.params.id : this.idsite.idsite).collection('members').doc(val.id).get()
      .then(docMember => {
        this.memberUpdated = docMember.data()
        this.memberUpdated.id = docMember.id
        this.memberUpdated.perm = perm

        if(docMember.data().status === 'invitated') {
          status = 'invitated'
          statusInv = 'sended'
        }
        db.collection('projects').doc(this.$route.params.id ? this.$route.params.id : this.idsite.idsite).collection('members').doc(val.id).update({
          status: status,
          statusInv: statusInv,
          perm: perm
        })
        .then(() => {
          if(perm === 'remove') {
            const user = auth.currentUser
            if(user) {
              db.collection('Users').doc(user.uid).update({
                updated: Date.now(),
              })
            }
          }
          // console.log('first')
          // this.charge()
          this.loader = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Permissions updated',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        })
      })
    },
    submitEditar(payload) {
      // this.$refs.sidebar_members_project.hide();
    },
    logIdSite(val) {
      // console.log(val)
      this.idsite = val
      this.showOpen = true
    },
    logIdChangeProject(val) {
      // console.log('first')
      this.idsite = val
      this.showOpenChangeProject = true
    },
    logIdArchive(val) {
      this.idsite = val
      this.showOpenArchiveSite = true
    },
    logIdMembersProject(val) {
      this.idsite = val
      this.showOpenMembersProject = true
    },
    logIdNameProject() {
      this.idsite = { id: this.$route.params.id, name: this.projectName }
      this.showOpenNameProject = true
    },
    getError(val) {
      this.$router.push({
        name: 'view-errors',
        params: {
          id: val.id, imgSite: val.imgSite, name: val.name, url: val.domain, project: val.projectid, own: val.own
        },
      })
    },
    onRowSelected(items) {
      if(items.column.field !== 'key' && items.column.field !== 'ownedBy' && items.column.field !== 'membership' && items.column.field !== 'action') {
        // console.log(items)
        this.$router.push({
          name: 'view-errors',
          params: {
            id: items.row.id, imgSite: items.row.imgSite, name: items.row.name, url: items.row.domain, project: items.row.projectid, own: items.row.own
          },
        })
      }
    },
    onCopy() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Key copied',
          icon: 'BellIcon',
          variant: 'success',
        },
      })
    },
    onError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Failed to copy key!',
          icon: 'BellIcon',
          variant: 'danger',
        },
      })
    },
    // sortSites() {
    //   if(this.rows[0] && this.rows[0].children.length >= 1) {
    //     this.rows[0].children.sort(function (a, b) {
    //       if(a.name.toLowerCase() > b.name.toLowerCase()) {
    //         return 1;
    //       }
    //       if(a.name.toLowerCase() < b.name.toLowerCase()) {
    //         return -1;
    //       }
    //       return 0;
    //     });
    //   }
    // },
    validationFormInvitation() {
      this.$refs.simpleRulesInvitation.validate().then(success => {
        if (success) {
          const user = auth.currentUser
          // console.log(user.email,this.projectInvitation.trim())
          if(user.email === this.projectInvitation.trim()) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `${this.projectInvitation} is already the owner of this project.`,
                icon: 'CoffeeIcon',
                variant: 'danger',
              },
            })
            this.projectInvitation = ''
            this.objInvitation = ''
          } else {
            if(this.projectInvitation) {
              var perm = ''
              if(this.permissionInvitation.title === 'Editor' || this.permissionInvitation === 'Editor') {
                perm = 'edit'
              } else if(this.permissionInvitation.title === 'Remove' || this.permissionInvitation === 'Remove') {
                perm = 'remove'
              } else if(this.permissionInvitation.title === 'Viewer' || this.permissionInvitation === 'Viewer') {
                perm = 'view'
              } else if(this.permissionInvitation.title === 'Admin/editor'  || this.permissionInvitation === 'Admin/editor') {
                perm = 'admin'
              }

              const filterEmail = this.projectInvitations.filter(item => item.email === this.projectInvitation)
              if (this.projectInvitations.length === 0 || filterEmail.length === 0) {
                this.invitationSending = true
                this.loader = true
                this.projectInvitations.push({
                  email: this.projectInvitation,
                  permission: perm,
                  status: 'invitated',
                  statusInv: 'sended',
                  // status: 'active',
                  // statusInv: 'accepted',
                  foto: this.objInvitation.foto,
                  name: this.objInvitation.name,
                  id: ''
                })

                // update members db
                // if(item.email.includes('@') && item.email.includes('.')) {
                const membersEmail = []
                const members = []
                const email = this.projectInvitation.trim()
                db.collection('Users').where('email', '==', email).get()
                .then(queryUser => {
                  let member = ''
                  // verify if user already exist, to add uid
                  if (queryUser.size >= 1) {
                    queryUser.forEach(userItem => {
                      members.push({
                        uid: userItem.id,
                        email,
                        perm: perm,
                        imgU: userItem.data().foto ? userItem.data().foto : '',
                        status: 'invitated',
                        statusInv: 'sended',
                        // status: 'active',
                        // statusInv: 'accepted',
                      })

                      member = {
                        uid: userItem.id,
                        email,
                        perm: perm,
                        imgU: userItem.data().foto ? userItem.data().foto : '',
                        status: 'invitated',
                        statusInv: 'sended',
                        // status: 'active',
                        // statusInv: 'accepted',
                      }

                      // update teams of user belongs
                      if (userItem.data().teams) {
                        db.collection('Users').doc(userItem.id).update({
                          teams: firebase.firestore.FieldValue.arrayUnion(this.$route.params.id ? this.$route.params.id : this.idsite.idsite),
                        })
                          .catch(error => {
                            console.error(error)
                          })
                      } else {
                        db.collection('Users').doc(userItem.id).update({
                          teams: [this.$route.params.id ? this.$route.params.id : this.idsite.idsite],
                        })
                          .catch(error => {
                            console.error(error)
                          })
                      }
                    })
                  } else {
                    members.push({
                      uid: '',
                      email,
                      perm: perm,
                      status: 'invitated',
                      statusInv: 'sended',
                      // status: 'active',
                      // statusInv: 'accepted',
                    })

                    member = {
                      uid: '',
                      email,
                      perm: perm,
                      status: 'invitated',
                      statusInv: 'sended',
                      // status: 'active',
                      // statusInv: 'accepted',
                    }
                  }

                  // send invitation mails
                  db.collection('projects').doc(this.$route.params.id ? this.$route.params.id : this.idsite.idsite).get()
                  .then(projectDoc => {
                    const obj = {
                      email,
                      name: this.owner.nombre ? this.owner.nombre : this.owner.email ? this.owner.email : '',
                      project: projectDoc.data().name,
                      existUser: queryUser.size >= 1 ? true : false
                    }

                    // update seocloud-dcdfb
                    // const config = {
                    //   method: 'POST',
                    //   url: 'https://us-central1-seocloud-7e24d.cloudfunctions.net/invitationMail',
                    //   headers: {
                    //     'Content-Type': 'application/json',
                    //   },
                    //   data: JSON.stringify(obj),
                    // }

                    // this.axios(config)
                    // .then(() => {
                    //   this.invitationSending = false
                    //   this.invitationSended = true
                    //   this.$toast({
                    //     component: ToastificationContent,
                    //     props: {
                    //       title: 'Invitation sent',
                    //       icon: 'BellIcon',
                    //       variant: 'success',
                    //     },
                    //   })
                    //   console.log('email sended')
                    // })
                    // .catch(error => {
                    //   console.log(error)
                    // })
                  })
                  // console.log(member,this.$route.params.id ? this.$route.params.id : this.idsite.idsite)

                  db.collection('projects').doc(this.$route.params.id ? this.$route.params.id : this.idsite.idsite).collection('members').add(member)
                  .then((docRef) => {
                    // console.log('first')
                    this.projectInvitations.forEach(ele => {
                      if(ele.email === email) {
                        // console.log(docRef.id)
                        ele.id = docRef.id
                      }
                    })
                    this.loader = false
                  })
                  .catch(error => {
                    console.error(error)
                  })
                  
                  if(user) {
                    db.collection('Users').doc(user.uid).update({
                      updated: Date.now(),
                    })
                  }
                })
                membersEmail.push(email)
                // }
                this.projectInvitation = ''
                this.objInvitation = ''
              } else {
                // this.projectInvitations.push({email: this.projectInvitation, perm: this.permission.title})
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `${this.projectInvitation} is already a team member.`,
                    icon: 'CoffeeIcon',
                    variant: 'danger',
                  },
                })
                this.projectInvitation = ''
                this.objInvitation = ''
              }
              // console.log(this.projectInvitations)
            }
          }
          // this.submitProject()
        }
      })
    },
    resendInvitation(payload) {
      // send invitation mails
      this.invitationSending = true
      db.collection('Users').where('email', '==', payload.email).get()
      .then(queryUs => {
        if(queryUs.size >= 1) {
          // queryUs.forEach(que => {
          const obj = {
            email: payload.email,
            name: this.owner.nombre ? this.owner.nombre : this.owner.email ? this.owner.email : '',
            project: payload.project,
            existUser: queryUs.size >= 1 ? true : false
          }

          // update seocloud-dcdfb
          // const config = {
          //   url: 'https://us-central1-seocloud-7e24d.cloudfunctions.net/invitationMail',
          //   method: 'POST',
          //   headers: {
          //     'Content-Type': 'application/json',
          //   },
          //   data: JSON.stringify(obj),
          // }

          // this.axios(config)
          // .then(() => {
          //   this.invitationSending = false
          //   // this.charge()
          //   this.$toast({
          //     component: ToastificationContent,
          //     props: {
          //       title: 'Invitation sent',
          //       icon: 'BellIcon',
          //       variant: 'success',
          //     },
          //   })

          //   console.log('email sended')
          //   db.collection('projects').doc(this.$route.params.id ? this.$route.params.id : this.idsite.idsite).collection('members').doc(payload.id).update({
          //     status: 'invitated',
          //     statusInv: 'sended',
          //     // status: 'active',
          //     // statusInv: 'accepted',
          //   })
          //   // .then(() => {
          //   //     this.$toast({
          //   //         component: ToastificationContent,
          //   //         props: {
          //   //             title: 'Invitation sent',
          //   //             icon: 'BellIcon',
          //   //             variant: 'success'
          //   //         },
          //   //     })
          //   // })

          //   db.collection('Users').doc(payload.uid).update({
          //     updated: Date.now(),
          //   })
          // })
          // .catch(error => {
          //   console.log(error)
          // })
          // })
        } else {
          const obj = {
            email: payload.email,
            name: this.owner.nombre ? this.owner.nombre : this.owner.email ? this.owner.email : '',
            project: payload.project,
            existUser: queryUs.size >= 1 ? true : false
          }

          // update seocloud-dcdfb
          // const config = {
          //   url: 'https://us-central1-seocloud-7e24d.cloudfunctions.net/invitationMail',
          //   method: 'POST',
          //   headers: {
          //     'Content-Type': 'application/json',
          //   },
          //   data: JSON.stringify(obj),
          // }

          // this.axios(config)
          // .then(() => {
          //   this.invitationSending = false
          //   // this.charge()
          //   this.$toast({
          //     component: ToastificationContent,
          //     props: {
          //       title: 'Invitation sent',
          //       icon: 'BellIcon',
          //       variant: 'success',
          //     },
          //   })

          //   console.log('email sended')
          //   db.collection('projects').doc(this.$route.params.id ? this.$route.params.id : this.idsite.idsite).collection('members').doc(payload.id)
          //     .update({
          //       status: 'invitated',
          //       statusInv: 'sended',
          //     // status: 'active',
          //     // statusInv: 'accepted',
          //     })
          //   // .then(() => {
          //   //     this.$toast({
          //   //         component: ToastificationContent,
          //   //         props: {
          //   //             title: 'Invitation sent',
          //   //             icon: 'BellIcon',
          //   //             variant: 'success'
          //   //         },
          //   //     })
          //   // })

          //   db.collection('Users').doc(payload.uid).update({
          //     updated: Date.now(),
          //   })
          // })
          // .catch(error => {
          //   console.log(error)
          // })
        }
      })
      .catch(error => {
        this.invitationSending = false
        console.log(error)
      })
    },
    selectHandler(val) {
      this.projectInvitation = val.item.email
      this.objInvitation = val.item
      // projectInvitation
    },
    onInputChange(text) {
      this.datasuggest = []
      this.datasuggestSort.sort(function (a, b) {
        if (a.name > b.name) {
            return 1;
        }
        if (a.name < b.name) {
            return -1;
        }
        return 0;
      });
      this.datasuggestSort.forEach(item => {
        // console.log(item.name)
        this.datasuggest.push(item)
      })

      this.projectInvitations.forEach(item => {
        this.datasuggest = this.datasuggest.filter(ele => ele.email !== item.email)
      })
      
      if (!text) {
        const filteredData = this.datasuggest
        this.filteredOptions = [{
          data: filteredData,
        }]
      } else {
        const filteredDevelopers = this.datasuggest.filter(item => item.email.toLowerCase().indexOf(text.toLowerCase()) > -1 || item.name.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, this.limit)
        const filteredData = filteredDevelopers
        this.filteredOptions = [{
          data: filteredData,
        }]
      }
    },
  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>

<style>
.col-content-edit-project {
  max-width: 930px !important;
}
.table-edit-project-sites {
  min-width: 716 !important;
  max-width: 930px !important;
}
.table-edit-project-sites td {
  padding: .1em .1em .1em .1em;
  border-left: 0px solid transparent !important;
  border-right: 0px solid transparent !important;
}
.tableEditProject th {
  background: #fff !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
  text-align: left !important;
  padding: .6em .1em .6em .1em !important;
}
.thTableSitesEditProject {
  cursor: initial;
  border: 1px solid transparent !important;
  vertical-align: middle !important;
}
.tdTableSitesEditProject {
  cursor: initial;
  border: 1px solid transparent !important;
  border-bottom: 1px solid #DCDCF2 !important;
  vertical-align: middle !important;
  padding-right: .3em !important;
  padding-left: .3em !important;
}
.thTableSitesEditProjectImg {
  background: transparent !important;
  /* border: 1px solid transparent !important; */
  color: #A7AAB9 !important;
  font-size: 15px !important;
  font-weight: 300;
  padding-right: 0.75em !important;

  width: 130px !important;
  max-width: 130px !important;
  min-width: 130px !important;
  border: 1px solid transparent !important;
  vertical-align: middle !important;
}
.tdTableSitesEditProjectImg {
  background: transparent !important;
  /* border: 1px solid transparent !important; */
  border-bottom: 1px solid #DCDCF2 !important;
  vertical-align: middle !important;
  width: 130px !important;
  max-width: 130px !important;
  min-width: 130px !important;

  /* width: 170px !important; */
  /* max-width: 170px !important; */
  border-left: 1px solid transparent !important;
  border-right: 1px solid transparent !important;
  /* vertical-align: middle !important; */
  padding-right: .3em !important;
  padding-left: .3em !important;
}
.thTableSitesEditProjectName {
  background: transparent !important;
  border: 1px solid transparent !important;
  color: #A7AAB9 !important;
  font-size: 15px !important;
  /* max-width: 425px; */
  font-weight: 300;
  padding-right: 0.75em !important;

  width: 200px !important;
  max-width: 200px !important;
  /* border: 1px solid transparent !important; */
  vertical-align: middle !important;
}
.tdTableSitesEditProjectName {
  background: transparent !important;
  /* border: 1px solid transparent !important; */
  color: #A7AAB9 !important;
  font-size: 15px !important;
  /* max-width: 425px; */
  /* font-weight: 300; */
  padding-right: 0.75em !important;
  /* margin: 20px !important; */
  width: 200px !important;
  max-width: 200px !important;
  min-width: 180px !important;

  /* width: 170px !important; */
  /* max-width: 170px !important; */
  border-left: 1px solid transparent !important;
  border-right: 1px solid transparent !important;
  border-bottom: 1px solid #DCDCF2 !important;
  vertical-align: middle !important;
  text-overflow: ellipsis;
  overflow-x: hidden;
}
.thTableSitesEditProjectDomain {
  background: transparent !important;
  border: 1px solid transparent !important;
  color: #A7AAB9 !important;
  font-size: 15px !important;
  /* max-width: 425px; */
  font-weight: 300;
  padding-right: 0.75em !important;

  /* width: 230px !important; */
  /* max-width: 230px !important; */
  /* border: 1px solid transparent !important; */
  vertical-align: middle !important;
}
.tdTableSitesEditProjectDomain {
  background: transparent !important;
  border: 1px solid transparent !important;
  border-bottom: 1px solid #DCDCF2 !important;
  cursor: pointer;
  /* max-width: 425px; */
  word-wrap: break-word;
  vertical-align: middle !important;
  color: rgb(167, 170, 185);
  font-size: 15px;
  /* margin: 20px !important; */

  /* width: 230px !important; */
  /* max-width: 230px !important; */
  border-left: 1px solid transparent !important;
  border-right: 1px solid transparent !important;
  /* vertical-align: middle !important; */
  /* text-overflow: ellipsis; */
  overflow-x: hidden;
  padding-right: .3em !important;
  padding-left: .3em !important;
}
.thTableSitesEditProjectOwned {
  background: transparent !important;
  border: 1px solid transparent !important;
  color: #A7AAB9 !important;
  font-size: 15px !important;
  /* max-width: 425px; */
  font-weight: 300;
  padding-right: 0.75em !important;
  /* margin: 20px !important; */
  width: 120px !important;
  max-width: 120px !important;
}
.tdTableSitesEditProjectOwned {
  background: transparent !important;
  /* border: 1px solid transparent !important; */
  border-bottom: 1px solid #DCDCF2 !important;
  vertical-align: middle !important;
  width: 120px !important;
  max-width: 120px !important;
  /* text-align: center !important; */
  vertical-align: middle !important;
  padding: 0 30px !important;
}
.action-edit-project button:hover {
  background-color: transparent !important;
}
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  background: #fff;
  border: none;
  /* color: #394066; */
  /* text-transform: lowercase; */
  /* font-variant: small-caps; */
}
.style-chooser-removed .vs__search::placeholder,
.style-chooser-removed .vs__dropdown-toggle,
.style-chooser-removed .vs__dropdown-menu {
  background: #fff;
  border: none;
}
.style-chooser-removed span {
  color: #FC7D7D !important;
}
.style-chooser-disabled .vs__search::placeholder,
.style-chooser-disabled .vs__dropdown-toggle,
.style-chooser-disabled .vs__dropdown-menu {
  background: #f8f8f8 !important;
  border: none;
  /* color: #394066; */
  /* text-transform: lowercase; */
  /* font-variant: small-caps; */
}
</style>
